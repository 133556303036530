.black-cell {
  background-color: #bdbdbd;
  color: #fff;
  font-weight: 600;
}

.orange-cell {
  background-color: #fff2cc;
  /* color: #000; */
}

.red-cell {
  background-color: #d32f2f4d;
  /* color: #fff; */  
}

.green-cell {
  background-color: #2e7d324d;
  /* font-weight: 400; */
}

.blue-cell {
  background-color: #a7c3eb;
}